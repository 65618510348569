<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>我的评语</el-breadcrumb-item>
    </el-breadcrumb>
    <el-collapse v-if="!errorflag" accordion
                 v-model="activeNames"
                 @change="handleChange"
                 class="comment">
      <el-collapse-item v-for="(item,index) in datainfo"
                        :key="index"
                        :name="index+1">
        <template slot="title">
          <div class="comtitle">
            <span class="badge" v-show="item.ReadStatus=='0'"></span>
            <p class="tit">
               <span>时间：{{item.OperationTime}}</span> <span>科目：{{item.Subject}}</span><span>学期：{{item.term}}</span><span>课程名称：{{item.course}}</span>
            </p>
            <span class="color1"
                  v-if="activeNames==''">展开</span>
            <span class="color1"
                  v-else-if='activeNames==(index+1)'>收起</span>
            <span class="color1"
                  v-else>展开</span>
          </div>
        </template>
        <div>
          <p>录入教师：<span class="color1">{{item.Teacher}}</span> </p>
          <p>课程评语：{{item.CommentInfo}}</p>
        </div>
      </el-collapse-item>
    </el-collapse>
     <div v-else class="errortips">{{errortips}}</div>
  </div>
</template>

<script>
import { comment_info,comment_read } from '@/api/classesinfo'
export default {
  name: 'questionset_detail',
  data () {
    return {
      activeNames: 0,
      datainfo: [],
      errortips:'',
      errorflag:false,
      userid:this.$store.state.user.stu_user_info[0].id
    };
  },
  created () {
    this.getInit()
  },
  methods: {
    async getInit () {
      let prames = {
        userid: this.userid,
      }
      let res = await comment_info(prames)
      if (res.flag == '1') {
        this.datainfo = res.datainfo;
        this.handleChange(this.activeNames)
      } else if (res.flag == '-1') {
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      } else if (res.flag == '2') {
        this.errorflag=true;
        this.errortips=res.msg
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          showClose: false,
          type: 'error',
        });
      }
    },
    handleChange (val) {
      this.activeNames = val;
      if(val!='' && this.datainfo[val-1].ReadStatus=='0'){
        this.setread(this.datainfo[val-1])
      }
    },
    async setread(item){
      let prames = {
        userid: this.userid,
        DBType:item.DBType,
        CommentID:item.CommentID
      }
      let res = await comment_read(prames)
      if (res.flag == '1') {
       this.$set(item,'ReadStatus','1')
      } else if (res.flag == '-1') {
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          showClose: false,
          type: 'error',
        });
      }  
    }
  },

}
</script>
<style lang="scss" scoped>
.comment {
  margin-top: 30px;
  border: none !important;
  .el-collapse-item {
    margin-bottom: 20px;
    border: 1px solid #e9e9ec;
    border-radius: 10px;
    overflow: hidden;
    background: #f1f2fa;
  }
  .color1 {
    width: 60px;
  }
  .comtitle{
    position: relative;
    justify-content: space-between;
    display: flex;
  }
  .tit span{
      margin-right: 5px;
    }
  .badge {
    position: absolute;
    top: -5px;
    left: -15px;
    width:10px;
    height: 10px;
    background: #f56c6c;
    border-radius: 50px;
  }
}
</style>
<style lang="scss">
.comment {
  .el-collapse-item__header,
  .el-collapse-item__wrap {
    border-bottom: 1px solid #e9e9ec !important;
    background: none !important;
    padding: 10px 25px !important;
    font-size: 18px !important;
    line-height: inherit !important;
  }
  .el-collapse-item__wrap {
    border-bottom: none !important;
    padding-top: 15px !important;
  }
  .el-collapse-item__content {
    font-size: 18px !important;
  }
  .el-collapse-item__arrow {
    color: #6f72ff;
  }

}
</style>