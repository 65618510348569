import request from '../utils/request'
//我的班级
export function classes_info (data) {
  return request({
    url: `/classesinfo/classes_info`,
    method: 'post',
    params: data
  })
}
//我的班级 -- 课程详情
export function get_classes_details (data) {
  return request({
    url: `/classesinfo/get_classes_details`,
    method: 'post',
    params: data
  })
}
//我的班级 -- 课程资料
export function get_classes_materials (data) {
  return request({
    url: `/classesinfo/get_classes_materials`,
    method: 'post',
    params: data
  })
}
//我的班级 -- 我的成绩
export function get_classes_score (data) {
  return request({
    url: `/classesinfo/get_classes_score`,
    method: 'post',
    params: data
  })
}

//课程资料
export function classes_materials (data) {
  return request({
    url: `/classesinfo/classes_materials`,
    method: 'post',
    params: data
  })
}
//课程资料下载查看
export function get_materials_details (data) {
  return request({
    url: `/classesinfo/get_materials_details`,
    method: 'post',
    params: data
  })
}
//拓展或堂测上传页
export function get_picture_submit (data) {
  return request({
    url: `/classesinfo/get_picture_submit`,
    method: 'post',
    params: data
  })
}
//拓展或堂测保存图片
export function picture_edit (data) {
  return request({
    url: `/classesinfo/picture_edit`,
    method: 'post',
    params: data
  })
}
//拓展或堂测批改结果页
export function get_picture_result (data) {
  return request({
    url: `/classesinfo/get_picture_result`,
    method: 'post',
    params: data
  })
}
//上传图片
export function picture_upload (data) {
  return request({
    url: `/classesinfo/picture_upload`,
    method: 'post',
    data,
    headers:{
      'Content-Type': 'multipart/form-data'  //之前说的以表单传数据的格式来传递fromdata
   }
  })
}

//我的成绩
export function classes_score (data) {
  return request({
    url: `/classesinfo/classes_score`,
    method: 'post',
    params: data
  })
}
//错题集
export function classes_mistakes (data) {
  return request({
    url: `/classesinfo/classes_mistakes`,
    method: 'post',
    params: data
  })
}
//错题集详情
export function get_mistakes_details (data) {
  return request({
    url: `/classesinfo/get_mistakes_details`,
    method: 'post',
    params: data
  })
}
//我的评语
export function comment_info (data) {
  return request({
    url: `/classesinfo/comment_info`,
    method: 'post',
    params: data
  })
}
//评语点击已读
export function comment_read (data) {
  return request({
    url: `/classesinfo/comment_read`,
    method: 'post',
    params: data
  })
}